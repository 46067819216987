import classNames from 'classnames'
import LoadingDots from 'components/loading-dots/LoadingDots'
import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import styles from './Price.module.scss'
import { getProposition, getTotalSimulatedPrice } from 'utils/price'
import { formatAmount } from 'utils/format'
import { useStoreSelector } from 'hooks/store'

const Price: FC = () => {
  // REDUX STORE
  const {
    flows: { simulation },
    inputs
  } = useStoreSelector((store) => store.customer)

  // i18n
  const { t } = useTranslation('producer')

  // Constants
  const proposition = getProposition()
  const withPromoCode = simulation.didSimulation && inputs.simulation.promoCode

  return (
    <>
      {simulation.didSimulation && !simulation.isLoading && (
        <div className={classNames('is-flex-row', 'is-flex-end', styles.price)}>
          <div className={classNames(styles.bubble, { [styles['with-promo-code']]: withPromoCode })}>
            {simulation.didSimulation && !simulation.isLoading && (
              <span className={styles.text}>
                {withPromoCode && (
                  <>
                    <span className={classNames(styles.number, styles['is-without-promo-code'])}>
                      <small>&euro;</small>
                      {formatAmount(proposition.priceWithoutPromo)}
                    </span>
                    <br />
                  </>
                )}

                <span className={styles.number}>
                  <small>&euro;</small>
                  {formatAmount(getTotalSimulatedPrice())}
                </span>
                <br />

                <span className={styles['per-month']}>/ {t('month', 'maand')}</span>
              </span>
            )}
          </div>
        </div>
      )}

      {simulation.isLoading && <LoadingDots />}
    </>
  )
}

export default Price
