import { useContext } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { Button } from '@boltenergy-be/design-system'
import { SimulationType } from 'store/marketing/types'
import { routes } from 'utils/routes'
import { ProducerCtaProps } from 'components/producers/producer-cta/types'
import { ProducerModalContext } from 'features/producer-detail/Context'
import { setSelectedProducer } from 'store/app/slice'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { startSimulationOrRegistration } from 'utils/customerFlow'
import { CtaTrackingVariant } from 'types/simulation'

const ProducerCta = ({ capacityState, isProducerDetail, isReserved, producerSlug, simulationSalesOffice }: ProducerCtaProps) => {
  // Redux store
  const { isLoading, didSimulation } = useStoreSelector((store) => store.customer.flows.simulation)
  const inputs = useStoreSelector((store) => store.customer.inputs)
  const simulationCompleted = didSimulation || isLoading
  const dispatch = useStoreDispatch()

  // Next router
  const router = useRouter()
  const { locale } = useRouter()

  // i18n
  const { t } = useTranslation(['producer', 'producers'])

  // Context (from ProducerDetail)
  const { setProducerModalOpen } = useContext(ProducerModalContext)

  if (isReserved) {
    return (
      <Button isFullwidth disabled>
        {t('reserved', 'Gereserveerd')}
      </Button>
    )
  }

  if (capacityState.soldOut) {
    return (
      <Button isFullwidth disabled>
        {t('soldOut', 'Uitverkocht')}
      </Button>
    )
  }

  if (isProducerDetail && !simulationCompleted) {
    return (
      <Button
        onClick={(e) => {
          e.stopPropagation()
          dispatch(setSelectedProducer({ producerSlug }))
          if (setProducerModalOpen) setProducerModalOpen(false)
          startSimulationOrRegistration({ ctaTrackingVariant: CtaTrackingVariant.PRODUCER_CARD, simulationSalesOffice })
        }}
        isFullwidth
        loading={isLoading}
      >
        {t('chooseLocation', 'Kies deze locatie')}
      </Button>
    )
  }

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        dispatch(setSelectedProducer({ producerSlug }))

        if (
          simulationCompleted ||
          ![SimulationType.PRICING_SIMULATION, SimulationType.EXPRESS].includes(inputs.simulation.chosenSimulationType)
        ) {
          return router.push(routes(locale, producerSlug).registration)
        } else {
          startSimulationOrRegistration({ ctaTrackingVariant: CtaTrackingVariant.PRODUCER_CARD, simulationSalesOffice })
        }
      }}
      isFullwidth
      loading={isLoading}
    >
      {t('choose', 'Kies')}
    </Button>
  )
}

export default ProducerCta
