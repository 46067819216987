import { FC, ReactElement } from 'react'
import { EnergyTypeIconProps as Props } from './types'
import { Bio, Sun, Water, Wind } from 'assets/svg'
import { EnergyType } from 'types/producer'
import styles from './EnergyTypeIcon.module.scss'

const EnergyTypeIcon: FC<Props> = ({ energyType, className }) => {
  /**
   * Returns the correct icon based on the energy type
   *
   * @returns {ReactElement}
   */
  const getIcon = (): ReactElement => {
    switch (energyType) {
      case EnergyType.ZON:
        return <Sun />

      case EnergyType.WATER:
        return <Water />

      case EnergyType.WIND:
        return <Wind />

      case EnergyType.BIO:
        return <Bio />

      default:
        return <Wind />
    }
  }

  return <div className={className || styles.icon}>{getIcon()}</div>
}

export default EnergyTypeIcon
