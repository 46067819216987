import { CldVideoPlayer } from 'next-cloudinary'
import classNames from 'classnames'
import type { VideoProps } from 'components/video/types'

const Video = ({ posterSeconds, publicId, wrapperClassName, ...props }: VideoProps) => {
  return (
    <div className={classNames('video-16-9', wrapperClassName)}>
      <CldVideoPlayer
        key={publicId}
        src={publicId}
        bigPlayButton="init"
        logo={false}
        {...(!!posterSeconds && {
          poster: `https://res.cloudinary.com/bolt-energie/video/upload/so_${posterSeconds},f_auto/v1/${publicId}.png`
        })}
        {...props}
      />
    </div>
  )
}

export default Video
