import classNames from 'classnames'
import styles from './LoadingDots.module.scss'

const LoadingDots = () => {
  return (
    <div className={styles['sk-three-bounce']}>
      <div className={classNames(styles['sk-child'], styles['sk-bounce1'])} />
      <div className={classNames(styles['sk-child'], styles['sk-bounce2'])} />
      <div className={classNames(styles['sk-child'], styles['sk-bounce3'])} />
    </div>
  )
}

export default LoadingDots
