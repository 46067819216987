import React from 'react'
import { ArticleShowcaseProps } from './types'
import ShowcaseWrapper from 'components/showcase/components/showcase-wrapper/ShowcaseWrapper'
import { routes } from 'utils/routes'
import ArticleCard from 'components/article/article-card/ArticleCard'

const ArticleShowcase = ({ articles, extraCardClassNames }: ArticleShowcaseProps) => {
  return (
    <ShowcaseWrapper>
      {articles.map((article) => {
        const destination = routes(article.locale, article.slug).blogArticle

        return <ArticleCard key={article.id} {...article} destination={destination} extraClassNames={extraCardClassNames} />
      })}
    </ShowcaseWrapper>
  )
}

export default ArticleShowcase
