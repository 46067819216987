import React from 'react'
import { ProducerShowcaseProps } from './types'
import ProducerCard from 'components/producers/producer-card/ProducerCard'
import ShowcaseWrapper from 'components/showcase/components/showcase-wrapper/ShowcaseWrapper'

const ProducerShowcase = ({ producers, simulationSalesOffice }: ProducerShowcaseProps) => {
  return (
    <ShowcaseWrapper>
      {producers.map((producer, idx) => (
        <div key={producer.id}>
          <ProducerCard key={`producer-${producer.slug}-${idx}`} {...{ producer, simulationSalesOffice }} showCTA />
        </div>
      ))}
    </ShowcaseWrapper>
  )
}

export default ProducerShowcase
