import { FC } from 'react'
import styles from './ProducerLocation.module.scss'
import EnergyTypeIcon from 'components/producers/energy-type-icon/EnergyTypeIcon'
import { getEnergyNameI18Key } from 'utils/producers'
import { useTranslation } from 'next-i18next'
import { ProducerLocationProps as Props } from 'components/producers/producer-location/types'
import classNames from 'classnames'

const ProducerLocation: FC<Props> = ({ energyType, energyName, location, boxed, className }) => {
  // i18n
  const { t } = useTranslation('producers')

  return (
    <div className={classNames(styles.wrapper, { [styles.boxed]: boxed }, className)}>
      <EnergyTypeIcon energyType={energyType} />
      <span className={styles.energy}>{energyName || t(getEnergyNameI18Key(energyType))}</span>
      {location && <span className={styles.location}>{location}</span>}
    </div>
  )
}

export default ProducerLocation
