import { createContext } from 'react'

type ProducerModalContextType = {
  producerModalOpen: boolean
  setProducerModalOpen: (isModalOpen: boolean) => void
}

export const ProducerModalContext = createContext<ProducerModalContextType>({
  producerModalOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setProducerModalOpen: () => {}
})
